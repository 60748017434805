@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face { 
    font-family: "PoetsenOne";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/PoetsenOne-Regular.ttf") format("truetype");
}

body {
    padding: 0;
    margin: 0;
    /* background-color: #191B1F; */
    color: #FFFFFF;
    font-family: "PoetsenOne", "-apple-system", "BlinkMacSystemFont", "Helvetica", "Tahoma", "Arial";
}

input {
    background-color: transparent;
    outline: none;
}